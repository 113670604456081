<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">典型案例来了！高处坠物工伤事故的伤残等级鉴定</div>
      <p class="time">2019.01.11</p>
      <p class="space_content" >
        还记得不久前上热搜的“高空坠狗”事件吗？
      </p>
      <p class="space_content" >
        广州的张女士被突然从高楼上的大狗砸中，狗主人身份成谜，狗也消失无踪，她却倒地昏迷不起，后经救治，负债累累，但仍高位截瘫。直至目前，狗主是何人仍然未知，无奈之下，张女士将“坠狗”大楼所有租客和房东告上法庭。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/21-1.png')">
      </div>

      <p class="space_content" >
        近年来，人们将类似高空抛物或意外的天降灾祸称之“悬在城市上空的痛”。长期以来，高空坠物致人伤亡的悲剧时有发生，坠落物有苹果、铁棍、甚至还有菜刀，五花八门，只有你想不到，没有坠不下的东西。由此而引发的伤人案及纠纷、诉讼等屡屡上演。
      </p>
      <p class="space_content" >
        前不久，我所也接到了一个法院委托的高空坠物案件——工伤事故的伤残等级鉴定，基本案情如下：
      </p>
      <p class="space_content" >
        原告黄某某受聘在被告谢某开办的食品批发店工作，其主要的工作为货物搬运及配送。某日，原告受被告指派送货到某区的便利店。原告在利用便利店升降电梯搬运货物的过程中，因升降机缆绳突然断裂，导致升降电梯自约3米高处坠落至地，造成原告受伤，被送医院诊治。如今，因原告黄某某的伤情尚未恢复，还需继续手术治疗，无法正常生活和工作，日常生活仍需要护理。原告与被告就工伤赔偿费用问题未能达成一致协议，因而原告向法院提起诉讼，要求申请伤残等级、护理期、营养期、以及后续治疗费用的司法鉴定。法院受理此案后，
        <span style="font-weight: 600;color: red">
          委托福建历思司法鉴定所
        </span>
        对黄某某工伤事故的 伤残等级、
        <span style="font-weight: 600">
          护理期、营养期、以及后续治疗费进行司法鉴定。
        </span>
      </p>
      <p class="space_content" >
        法医临床检验：查体：左侧外侧见“L”型12.5cm术后瘢痕形成，右足外侧见“L”型9.2cm术后瘢痕形成，双侧踝关节外观无畸形、肿胀，双踝关节活动功能尚可，双足各趾活动尚可。
      </p>
      <p class="space_content" >
        按委托方要求，根据其提供的现有案情材料及辅助检查资料，结合本所鉴定人检验所见综合分析认为：
      </p>
      <p class="space_content" >
        被鉴定人黄某某外伤致双侧跟骨粉碎性骨折，经本所鉴定人阅CT复查片示其跟距关节面不平整，并骨质局部硬化，关节间隙变窄，关节边缘骨赘形成等创伤性关节炎影像学改变，符合本标准双侧跟骨骨折畸形愈合的判断依据，参照《人体损伤致残程度分级》标准第5.9.6.13）条款之规定，其伤残等级评定为九级伤残。
      </p>
      <p class="space_content" >
        参照GA/T 1193-2014 人身损害误工期、护理期、营养期评定规范，评定被鉴定人黄某某的护理期限为损伤后150日，营养期限为损伤后90日。
      </p>
      <p class="space_content" >
        1.被鉴定人黄某某损伤后的后遗症，参照《人体损伤致残程度分级》标准，评定为
        <span style="font-weight: 600">
          九级伤残。
        </span>
      </p>
      <p class="space_content" >
        2.建议被鉴定人黄某某的
        <span style="font-weight: 600">
          护理期限为损伤后150日，营养期限为损伤后90日。
        </span>
      </p>
      <p class="space_content" >
        3.被鉴定人黄某某的后续治疗费用（左侧跟骨取内固定），建议以右侧跟骨内固定物取出术实际产生的费用为准。
      </p>
      <p class="space_content" >
        法院根据福建历思司法鉴定所提供的司法鉴定意见书，判决了工伤赔偿数额，最终双方达成了和解协议。
      </p>
      <p class="space_content" >
        此工伤事故伤残等级鉴定的案例，评选上了中国法律服务网，被作为司法行政（法律服务）的典型案例收录在案例库中了！
      </p>
    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/20'"><p class="ellipsis1">厦门市湖里区林重阳区长一行莅临考察福建历思司法鉴定所</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/22'"><p class="ellipsis1">朱墨时序鉴真伪，却是老赖“挖坑”埋了自己！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
